import { combineReducers, legacy_createStore as createStore } from 'redux'
import { baseReducer, contactReducer, exchangeRateReducer, fundReducer } from '../reducer'

export const store = createStore(
    combineReducers({
        base: baseReducer,
        contact: contactReducer,
        exchangeRate: exchangeRateReducer,
        fund: fundReducer,
    })
)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
