import { LoaderFunctionArgs } from 'react-router-dom'
import { getFund } from '../action/fund'

export const getFundLoader = async ( args: LoaderFunctionArgs ) => {

    if(args.request.url.includes('/konzervativ') ){
        await getFund( 1 )
    }

    if(args.request.url.includes('/kiegyensulyozott') ){
        await getFund( 2 )
    }

    if(args.request.url.includes('/dinamikus') ){
        await getFund( 3 )
    }

    return null
}

