import { Menu } from '../../../enum'
import { useAppTranslation } from '../../../hook'
import './tab.docs.scss'

interface TabDocsProps {
    type: Menu
}

export const TabDocs = (props: TabDocsProps) => {
    const { t, i18n } = useAppTranslation()

    const getNewFirstDoc = () => {
        if (props.type === Menu.FoundsConservative) {
            return '/docs/conservative/bg/OTPMultiAssetConservativeFundofFunds09042024cleanBG.pdf'
        }
        if (props.type === Menu.FoundsBalanced) {
            return '/docs/balanced/bg/OTPMultiAssetBalancedFundofFunds20240724vknBGN.pdf'
        }
        return '/docs/dynamic/bg/OTPMultiAssetDynamicFundofFunds20240720vknBG.pdf'
    }

    const getFirstDoc = () => {
        if (i18n.language.includes('mne')) {
            if (props.type === Menu.FoundsConservative) {
                return '/docs/conservative/mne/OTPMultiAssetConservativeFundofFunds09042024.pdf'
            }
            if (props.type === Menu.FoundsBalanced) {
                return '/docs/balanced/mne/OTPMultiAssetBalancedFundofFunds20240724.pdf'
            }
            return '/docs/dynamic/mne/OTPMultiAssetDynamicFundofFunds20240720.pdf'
        } else {
            if (props.type === Menu.FoundsDynamic) {
                return '/docs/dynamic/OTP Multi-Asset Dynamic Fund of Funds 20240720.pdf'
            }
            if (props.type === Menu.FoundsBalanced) {
                return '/docs/balanced/OTP Multi-Asset Balanced Fund of Funds 20240724.pdf'
            }
            return '/docs/conservative/OTP Multi-Asset Conservative Fund of Funds 09042024.pdf'
        }
    }

    const getSecondDoc = () => {
        if (i18n.language.includes('sl')) {
            if (props.type === Menu.FoundsConservative) {
                return '/docs/conservative/sl/OTPMultiAssetConservativeFundofFundsKID_28062024_SL.pdf'
            }
            if (props.type === Menu.FoundsBalanced) {
                return '/docs/balanced/sl/OTPMultiAssetBalancedFundofFundsKID_28062024_SL.pdf'
            }
            return '/docs/dynamic/sl/OTPMultiAssetDynamicFundofFundsKID_28062024_SL.pdf'
        } else if (i18n.language.includes('bg')) {
            if (props.type === Menu.FoundsConservative) {
                return '/docs/conservative/bg/OTPMultiAssetConservativeFundofFundsKID20240628BG.pdf'
            }
            if (props.type === Menu.FoundsBalanced) {
                return '/docs/balanced/bg/OTPMultiAssetBalancedFundofFundsKID20240628BG.pdf'
            }
            return '/docs/dynamic/bg/OTPMultiAssetDynamicFundofFundsKID20240628BG.pdf'
        } else if (i18n.language.includes('mne')) {
            if (props.type === Menu.FoundsConservative) {
                return '/docs/conservative/mne/OTPMultiAssetConservativeFundofFundsKID_28062024_vkn_SLA.pdf'
            }
            if (props.type === Menu.FoundsBalanced) {
                return '/docs/balanced/mne/OTPMultiAssetBalancedFundofFundsKID_ 28062024_vkn_SLA.pdf'
            }
            return '/docs/dynamic/mne/OTPMultiAssetDynamicFundofFundsKID_28062024_vkn_SLA.pdf'
        } else if (i18n.language.includes('hr')) {
            if (props.type === Menu.FoundsConservative) {
                return '/docs/conservative/hr/OTPMultiAssetKonzervativniFondFondovaNovaVerzijaHR20240628.pdf'
            }
            if (props.type === Menu.FoundsBalanced) {
                return '/docs/balanced/hr/OTPMultiAssetUravnotezeniFondFondovaNovaVerzijaHR20240628.pdf'
            }
            return '/docs/dynamic/hr/OTPMultiAssetDinamckiFondFondovaNovaVerzijaHR20240628.pdf'
        } else {
            if (props.type === Menu.FoundsConservative) {
                return '/docs/conservative/en/OTPMultiAssetConservativeFundofFundsKID28062024ENG.pdf'
            }
            if (props.type === Menu.FoundsBalanced) {
                return '/docs/balanced/en/OTPMultiAssetBalancedFundofFundsKID28062024ENG.pdf'
            }
            return '/docs/dynamic/en/OTPMultiAssetDynamicFundofFundsKID28062024ENG.pdf'
        }
    }

    const getThirdDoc = () => {
        if (i18n.language.includes('bg')) {
            if (props.type === Menu.FoundsConservative) {
                return '/docs/conservative/bg/OTPMultiAssetConservativeFundofFunds202408070420bg.pdf'
            }
            if (props.type === Menu.FoundsBalanced) {
                return '/docs/balanced/bg/OTPMultiAssetBalancedFundofFunds202408070420bg.pdf'
            }
            return '/docs/dynamic/bg/OTPMultiAssetDynamicFundofFunds202408070420bg.pdf'
        } else {
            if (props.type === Menu.FoundsConservative) {
                return '/docs/conservative/en/OTPMultiAssetConservativeFundofFunds202408070420.pdf'
            }
            if (props.type === Menu.FoundsBalanced) {
                return '/docs/balanced/en/OTPMultiAssetBalancedFundofFunds202408070420.pdf'
            }
            return '/docs/dynamic/en/OTPMultiAssetDynamicFundofFunds202408070420.pdf'
        }
    }

    const getHrefLink = () => {
        if (props.type === Menu.FoundsConservative) {
            return 'https://www.otpbank.hu/otpalapkezelo/hu/A_Multi_Asset_Konzervativ'
        }
        if (props.type === Menu.FoundsBalanced) {
            return 'https://www.otpbank.hu/otpalapkezelo/hu/A_Multi_Asset_Kiegyensulyozott'
        }
        return 'https://www.otpbank.hu/otpalapkezelo/hu/A_Multi_Asset_Dinamikus'
    }

    return (
        <main className="tab-docs">
            {i18n.language.includes('hu') ? (
                <a target="_blank" rel="noopener noreferrer" href={getHrefLink()}>
                    {t('founds_href_title')}
                </a>
            ) : (
                <ul>
                    {i18n.language.includes('bg') ? (
                        <li>
                            <a href={getNewFirstDoc()} download>
                                <img alt="Pdf logo" className="icon" src="/image/ic_pdf.svg" /> {t('founds_conservative_docs_first_title')} - {`Bulgarian`}
                            </a>
                        </li>
                    ) : null}

                    <li>
                        <a href={getFirstDoc()} download>
                            <img alt="Pdf logo" className="icon" src="/image/ic_pdf.svg" /> {t('founds_conservative_docs_first_title')}
                        </a>
                    </li>
                    <li>
                        <a href={getSecondDoc()} download>
                            <img alt="Pdf logo" className="icon" src="/image/ic_pdf.svg" /> {t('founds_conservative_docs_second_title')}
                        </a>
                    </li>
                    <li>
                        <a href={getThirdDoc()} download>
                            <img alt="Pdf logo" className="icon" src="/image/ic_pdf.svg" /> {t('founds_conservative_docs_third_title')}
                        </a>
                    </li>
                </ul>
            )}
        </main>
    )
}
